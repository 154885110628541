// extracted by mini-css-extract-plugin
export var cell = "HivelocityBareMetalPricing__cell__OW4ln";
export var cellGray = "HivelocityBareMetalPricing__cellGray__QUNpO";
export var column = "HivelocityBareMetalPricing__column__KGDhc";
export var desktop = "HivelocityBareMetalPricing__desktop__qkXhu";
export var headerCell = "HivelocityBareMetalPricing__headerCell__ZDIme";
export var headerRow = "HivelocityBareMetalPricing__headerRow__KJp1w";
export var heading = "HivelocityBareMetalPricing__heading__XQm1Z";
export var horizontalScrollRow = "HivelocityBareMetalPricing__horizontalScrollRow__uRu8n";
export var large = "HivelocityBareMetalPricing__large__RjOlx";
export var medium = "HivelocityBareMetalPricing__medium__lLZCw";
export var meta = "HivelocityBareMetalPricing__meta__Udsrz";
export var metaCell = "HivelocityBareMetalPricing__metaCell__PGYQL";
export var metaFields = "HivelocityBareMetalPricing__metaFields__HXiJq";
export var mobile = "HivelocityBareMetalPricing__mobile__V7H7F";
export var mobileOnlyMeta = "HivelocityBareMetalPricing__mobileOnlyMeta__jirjd";
export var price = "HivelocityBareMetalPricing__price__hiuoJ";
export var pricingCell = "HivelocityBareMetalPricing__pricingCell__TAv28";
export var pricingRow = "HivelocityBareMetalPricing__pricingRow__HKvVM";
export var pricingTable = "HivelocityBareMetalPricing__pricingTable__xADD8";
export var row = "HivelocityBareMetalPricing__row__WI3t3";
export var small = "HivelocityBareMetalPricing__small__Gik9H";
export var spacer = "HivelocityBareMetalPricing__spacer__iFdJV";
export var subheading = "HivelocityBareMetalPricing__subheading__R8Slh";
export var title = "HivelocityBareMetalPricing__title__T9uQy";
export var wrapper = "HivelocityBareMetalPricing__wrapper__FuYwD";