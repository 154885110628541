// extracted by mini-css-extract-plugin
export var bannerContainer = "HivelocityCaseStudyBanner__bannerContainer__hR4TP";
export var column = "HivelocityCaseStudyBanner__column__XMClN";
export var ctaWrapper = "HivelocityCaseStudyBanner__ctaWrapper__YJVNp";
export var description = "HivelocityCaseStudyBanner__description__Ye_PN";
export var row = "HivelocityCaseStudyBanner__row__CcxiJ";
export var testimonialText = "HivelocityCaseStudyBanner__testimonialText__o6IbH";
export var testimonialWrapper = "HivelocityCaseStudyBanner__testimonialWrapper__IDMBF";
export var title = "HivelocityCaseStudyBanner__title__pN3fE";
export var wrapper = "HivelocityCaseStudyBanner__wrapper__z6lFM";
export var wrapperBackgroundImage = "HivelocityCaseStudyBanner__wrapperBackgroundImage__Z9Agd";
export var wrapperContainer = "HivelocityCaseStudyBanner__wrapperContainer__voR69";